<template>
  <div class="sidebar-parent">
    <div class="side-navbar" id="mainSideBar">
      <div class="flex items-center justify-between p-4 border-b-2 border-white" style="margin-top: -5px;">
        <div class="flex items-center">
          <img src="../../../assets/image.png" class="h-10 w-10 mr-4" alt="Logo">
          <span id="header-text" class="text-white font-bold">EHPL</span>
        </div>
        <div class="mobile-close text-white items-end" @click="toggleSidebar">
          <i class="fa-solid fa-xmark text-3xl bx-bold"></i>
        </div>
      </div>

      <ul class="mt-24">
        <li class="list active" id="activeMenu">
          <b></b>
          <b></b>
          <a href="#Home">
            <router-link class="link" to="/Applicant/NewLicense">
              <span class="icon"
                ><i
                  :class="
                    isDarkMode
                      ? 'bx bx-home text-white'
                      : 'bx bx-home text-main-400'
                  "
                ></i
              ></span>
              <span
                :class="isDarkMode ? 'text-white title' : 'text-main-400 title'"
                >{{$t('Home')}}</span
              >
            </router-link>
          </a>
        </li>
        <li class="list">
          <b></b>
          <b></b>
          <a href="#NewLicense">
            <router-link class="link" to="/Applicant/NewLicense">
              <span class="icon"
                ><i class="bx bx-certification text-white"></i
              ></span>
              <span class="text text-white font-bold">{{$t('New License')}}</span>
            </router-link>
          </a>
        </li>

        <li class="list">
          <b></b>
          <b></b>
          <a href="#Renewal">
            <router-link class="link" to="/Applicant/Renewal">
              <span class="icon"><i class="bx bx-refresh text-white"></i></span>
              <span class="text text-white font-bold">{{$t('Renewal')}}</span>
            </router-link>
          </a>
        </li>

        <li class="list">
          <b></b>
          <b></b>
          <a href="#GoodStanding">
            <router-link class="link" to="/Applicant/GoodStanding">
              <span class="icon"
                ><i class="bx bx-mail-send text-white"></i
              ></span>
              <span class="text text-white font-bold">{{$t('Good Standing')}}</span>
            </router-link>
          </a>
        </li>
        <li class="list">
          <b></b>
          <b></b>
          <a href="#LostLicense">
            <router-link class="link" to="/Applicant/LostLicense">
              <span class="icon"><i class="bx bx-recycle text-white"></i></span>
              <span class="text text-white font-bold">{{$t('Lost License')}}</span>
            </router-link>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: ["isDarkMode"],
  methods: {
    toggleSidebar() {
      let sidenavbar = document.querySelector(".side-navbar");
      let content = document.querySelector(".content");
      let sidenavbarHeader = document.querySelector("#header-text");
      sidenavbar.classList.toggle("active");
      content.classList.toggle("active");
      sidenavbarHeader.classList.toggle("disable-header-text");
    }
  },
};
</script>